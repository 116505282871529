
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'shipment-size-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const shpmentSchema = Yup.object().shape({
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleKu: Yup.string()
          .required(() => translate('KU_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ku'),
        descriptionAr: Yup.string()
          .required(() => translate('AR_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description Ar'),
        descriptionEn: Yup.string()
          .required(() => translate('EN_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description En'),
        descriptionKu: Yup.string()
          .required(() => translate('KU_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description Ku'),
        width: Yup.number()
          .required(() => translate('WIDTH_IS_REQUIRED_FIELD'))
          .label('width'),
        height: Yup.number()
          .required(() => translate('HEIGHT_IS_REQUIRED_FIELD'))
          .label('height'),
        weight: Yup.number()
          .required(() => translate('WEIGHT_IS_REQUIRED_FIELD'))
          .label('weight'),
      });
      const onSubmitCreate = async (values) => {
        values.title = [];
        values.description = [];
        values.title.push({ languageCode: 'en', title: values.titleEn });
        values.title.push({ languageCode: 'ar', title: values.titleAr });
        values.title.push({ languageCode: 'ku', title: values.titleKu });

        values.description.push({
          languageCode: 'en',
          title: values.descriptionAr,
        });
        values.description.push({
          languageCode: 'ar',
          title: values.descriptionEn,
        });
        values.description.push({
          languageCode: 'ku',
          title: values.descriptionKu,
        });

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const payload = {
          sensivity: 1,
          title: values.title,
          description: values.description,
          width: Number(values.width),
          height: Number(values.height),
          weight: Number(values.weight),
        };
        await store.dispatch(Actions.CREATE_SHIPMENT_SIZE, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_SHIPMENT_SIZE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'shipment-size-listing' });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('shipmentSizeCreating'), [
          translate('assets'),
        ]);
      });

      return {
        onSubmitCreate,
        submitButton,
        translate,
        shpmentSchema,
        goBack,
        can,
      };
    },
  });
